<template>
  <div class="content gradient">
    <article class="row text-center"
    >

      <vue-whatsapp-widget
        phoneNumber="+524421867547"
        companyName="tulum.party"
        textReply="Typically replies within 10-15 mins (8am - 10pm)"
        companyLogo="https://imgix.cosmicjs.com/08f3cb30-e877-11ec-9177-7506dda64181-2022-06-09-23.37.07.jpg?h=50"
      />

      <section class="text-center col-lg-8 col-sm-12">
        <HeadImage :image="mainImage" title="TULUM BEACH CLUBS" align="text-center"
                   caption="Tulum best clubs to spend your day."
        ></HeadImage>


        <p style="font-size: 1rem;margin-top: 10px"> Lose no Time, The Beach Await !</p> <br>
        <p>Day parties, day passes, pure delight</p>

        <p>
        <strong
          style="font-size: 0.8rem;">
          Make Your Day
          Unforgettable!</strong></p>


        <br>
        <br>
        <card
          class="card text-center "
          style="
        background: linear-gradient(#23bac4, #66d0dd);
        z-index: 1;width: 90%;margin-left: auto;margin-right: auto;left: 0;right: 0;margin-bottom: 50px;padding: 10px;">
          <p style="font-size: 0.8rem"><strong>Unleash the Magic of Tulum's Blue Beaches</strong></p>

          <br>

          <div class="col-12">
            <RsvpBeachClubsViaDialog></RsvpBeachClubsViaDialog>
          </div>


          <br>

          <small style="color: black">
            We operate from 8AM - 11PM / Answer within 15 mins</small
          >
          <p style="font-size: 0.7rem;font-weight: bold">
            Your All-In-One Solution for a Successful Celebration!
          </p>

        </card>
        <img class=" d-none d-sm-inline floating"
             v-lazy="birds"
             height="130px" style="z-index: 1;opacity: 0.7;margin: 20px">

        <div class="row gradient">

          <div
            class="col-sm-12"
            v-for="(row, idx) in venues"
            :key="idx"
            style="
            box-shadow: #000000 10px 10px 2px;
            padding: 30px;
            flex-flow: column;
          "
          >
            <img
              v-bind:alt="row.title"
              v-lazy="row.metadata.main_image.imgix_url + '?h=430'"
              height="430px"
              v-if="row.metadata.main_image"
              style="box-shadow: 0px 10px 15px 0px rgb(0 0 0.6); border-top: 13px solid black; border-bottom: 13px solid black;border-right: 1px solid black;border-left: 1px solid black"
            />
            <br/>
            <br/>
            <h5 class="tulum-party-title" style="margin-top: -1px;text-shadow: black 1px 1px 1px;">
              {{ row.title }}
            </h5>
            <div
              style="
              background-color:rgba(15, 14, 14, 0.7) !important;
              margin-bottom: 10px;
              padding: 10px;
              line-height: 1.5rem;
              border-radius: 5px;
            "
              class="center-justified"
            >

              <div v-html="row.metadata.description"></div>
              <Collapse>
                <CollapseItem title="Details">
                  <div v-html="row.content"></div>
                </CollapseItem>
              </Collapse>
            </div>
            <!--      DESKTOP -->
            <br/>
            <RsvpBeachClubsViaDialog :row="row"></RsvpBeachClubsViaDialog>
          </div>
        </div>
        <SmartMenu :links="links" :logo="this.mainImage" ctaText="DROP US A LINE" top-section-name="TULUM.PARTY">
          <div class="row ">
            <div class="col-sm-12 text-center">
              <p style="color: #efefef !important;">Feeling Lost?</p>
              <hr>
              <p style="color: #efefef !important;">
                We Are Your Single Contact for All Clubs & Events: <strong>Simplify your planning!</strong>
              </p>
              <hr>
            </div>
          </div>
        </SmartMenu>


      </section>

      <aside class="card col-4 col-lg-4 d-none d-lg-inline "
      >

        <SmartMenu :links="links" :logo="this.mainImage" ctaText="DROP US A LINE" top-section-name="TULUM.PARTY">
          <div class="row ">
            <div class="col-sm-12 text-center">
              <p style="color: #efefef !important;">Feeling Lost?</p>
              <hr>
              <p style="color: #efefef !important;">
                We Are Your Single Contact for All Clubs & Events: <strong>Simplify your planning!</strong>
              </p>
              <hr>
            </div>
          </div>
        </SmartMenu>
      </aside>
    </article>
  </div>
</template>

<script>
import Collapse from '@/components/Collapse/Collapse';
import CollapseItem from '@/components/Collapse/CollapseItem';
import SmartMenu from "@/components/SmartMenu/SmartMenu";
import HeadImage from "@/components/HeadImage/HeadImage";
import ResponsiveImage from "@/components/HeadImage/ResponsiveImage";

const VueWhatsappWidget = () => import( /* webpackChunkName: "vue-whatsapp-widget" */'../../components/WhatsAppWidget/Widget');
const RsvpBeachClubsViaDialog = () => import( /* webpackChunkName: "RsvpDialogs" */'../../components/Reservations/RsvpBeachClubsViaDialog');

export default {
  name: 'BeachClubs.vue',
  data: () => {
    return {
      showPalms: false,
      links: [{
        metadata:
          {
            text: 'Affordable Tulum Beach Clubs',
            caption: 'How to enjoy the beach on a budget',
            link_to: '/article/affordable-tulum-beach-clubs',
            image: {
              imgix_url: 'https://imgix.cosmicjs.com/8e0e4a10-5fc2-11ef-b5ae-a594bb4a8e67-30861412.jpg'
            }
          }
      }],
      bgPalm:
        'https://imgix.cosmicjs.com/d215fe40-8144-11ee-9ebe-eb22b0517bf0-IMG0515.PNG',
      mainImage:
        'https://imgix.cosmicjs.com/031c4bf0-e6e8-11ec-9447-f98173199613-telegram-cloud-photo-size-1-4972161472985672258-y.jpg',
      birds:
        "https://imgix.cosmicjs.com/63c3dfe0-9b0f-11ee-b34c-99d1d89e42eb-image.png"
    };
  },
  metaInfo: () => ({
    title: 'Tulum Beach & Day Clubs. Information & Reservations.',
    link: [
      {
        rel: 'canonical',
        href: 'https://www.tulum.party/tulum-day-beach-clubs',
      },
    ],
    meta: [
      {
        name: 'description',
        content:
          'Top Day/Night Clubs @ Tulum Mexico. Information & Reservations.',
      },
      {
        name: 'keywords',
        content:
          'Tulum, Day CLub, clubs, beach clubs, Today,  2022, Papaya Playa, Vagalume, Events, Bagatelle, Taboo, Tantra',
      },
      {
        property: 'og:title',
        content: `Tulum Beach & Day Clubs. Information & Reservations.`,
      },
      {property: 'og:type', content: 'website'},
      {property: 'og:locality', content: 'Tulum'},
      {property: 'og:country-name', content: 'Mexico'},
      {
        property: 'og:description',
        content: `Top Beach/Day Clubs @ Tulum Mexico`,
      },
      {
        property: 'og:image',
        content:
          'https://imgix.cosmicjs.com/82856090-f18b-11ec-8fb8-5d396858ac9b-photo2022-06-2112-46-08.jpg?h=300&w=300&fit=clamp',
      },
    ],
  }),
  components: {
    ResponsiveImage,
    HeadImage,
    SmartMenu,
    RsvpBeachClubsViaDialog,
    CollapseItem,
    Collapse,
    VueWhatsappWidget,
  },
  computed: {
    venues: {
      get() {
        return this.$store.state.beach_clubs;
      },
    },
    faqs: {
      get() {
        return this.$store.state.faqs_category;
      },
    },
  },
  created() {
    this.$store.dispatch('getBeachClubs', {start: 0, limit: 12});
    this.$store.dispatch('getFaqsByCategory', 'Clubs');
    window.history.scrollRestoration = 'manual';
  },
  updated() {
    // setTimeout(() => (this.showPalms = true), 5000);
  },
  methods: {
    onJoinInstagramClick() {
      window.ga('send', 'event', 'instagram', 'JOIN', 'Main', 5);
      this.$rollbar.info('Instagram join');
    },
  },
};
</script>
<style scoped>
p,
h1,
h2,
h3,
h4 {
  color: black !important;
}

button {
  color: black !important;
}
</style>
